/*------------------------------------
    Header
------------------------------------*/

// Header 1
.app-header-1 {
    z-index: 99;
    width: 100%;
    max-width: 100%;
    position: fixed;

    .header-logo {
        top: 20px;
        left: 30px;
        z-index: 12;
        font-size: 20px;
        font-weight: 600;
        position: absolute;
    }
    .header-menu {
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        position: fixed;
        list-style: none;
        overflow: auto;
        white-space: nowrap;
        padding-left: 30px;
        margin-bottom: 0;
        background: $base-1;
        -ms-overflow-style: none;
        border-top: 1px solid $primary;
        border-bottom: 1px solid $primary;

        li {
            color: $base-0;
            font-size: 12px;
            margin-right: 25px;
            display: inline-block;
            padding: 7px 0 6px;

            .active {
                color: $primary;
                font-weight: 700;
            }
        }
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .header-dots {
        top: 50%;
        right: 30px;
        width: 20px;
        height: 300px;
        padding: 0;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: stretch;
        position: fixed;
        list-style: none;
        margin-top: -150px;

        li {
            margin-bottom: 14px;

            a {
                display: block;

                .circle {
                    width: 10px;
                    height: 10px;
                    display: block;
                    border-radius: 50%;
                    border: 1px solid $base-1;
                }
                &.active {
                    .circle {
                        border-color: $primary;
                        background-color: $primary;
                    }
                }
            }
        }
    }
    .button-menu {
        top: 10px;
        right: 30px;
        width: 50px;
        height: 50px;
        z-index: 99;
        position: absolute;
        padding-top: 12px;

        span {
            width: 36px;

            &,
            &:before,
            &:after {
                height: 3px;
                float: right;
                display: block;
                position: relative;
                background-color: $base-1;
            }
            &:before {
                top: -12px;
                width: 30px;
                content: "";
            }
            &:after {
                bottom: -10px;
                width: 30px;
                content: "";
            }
        }
    }
    .header-follow {
        top: 50%;
        left: 30px;
        width: 20px;
        height: 200px;
        position: fixed;
        margin-top: -100px;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                display: block;
                font-size: 12px;
                text-align: center;
                margin-bottom: 10px;
                transition: all 300ms;

                &:hover {
                    transform: scale(1.5, 1.5);
                }
            }
        }

        p {
            top: 70px;
            left: -24px;
            width: 68px;
            height: 20px;
            color: $base-1;
            font-size: 12px;
            font-weight: 600;
            line-height: 1.2;
            white-space: nowrap;
            position: relative;
            transform: rotate(-90deg);

            &:after {
                top: 9px;
                right: -48px;
                width: 40px;
                height: 1px;
                content: "";
                display: block;
                position: absolute;
                background-color: $base-1;
            }
        }
    }
}

@media (max-width: 1024px) {
    .app-header {
        .header-logo {
            top: 15px;
            left: 15px;
        }
        .header-dots {
            right: 15px;
        }
        .button-menu {
            top: 15px;
            right: 15px;
        }

        .header-follow {
            left: 15px;
        }
    }
}

@media (max-width: 992px) {
    .app-header {
        position: absolute !important;

        .header-logo {
            top: 14px;
            left: 18px;
        }
        .button-menu {
            top: 7px;
            right: 10px;
        }
        .header-dots {
            right: 0 !important;
        }
        .header-follow {
            left: 10px;
        }
    }
}

@media (max-width: 768px) {
    .app-header {
        .header-dots,
        .header-follow {
            display: none !important;
        }
    }
}
